<template>
	<div class="login">
		<div class="login_form">
			<Card>
				<Form ref="form" :model="form">
					<FormItem prop="username">
						<Input type="text" v-model.trim="form.username" placeholder="请输入用户名称"
							style="width: 280px;text-align:center;">
						<Icon type="md-person" slot="prepend" />
						</Input>
					</FormItem>
					<FormItem prop="password">
						<Input type="password" v-model.trim="form.password" placeholder="请输入用户密码" style="width: 280px">
						<Icon type="md-unlock" slot="prepend" />
						</Input>
					</FormItem>
					<FormItem>
						<Button type="primary" @click="handleSubmit('form')" style="width: 280px">登陆</Button>
					</FormItem>
          <FormItem>
            <Button type="warning" @click="handleRegister" style="width: 280px">注册</Button>
          </FormItem>
				</Form>
			</Card>
		</div>

    <!-- 新建账号对话框 -->
    <Modal title="注册账号" v-model="registerModel" width="800px" @on-ok="register_ok" @on-cancel="register_cancel" class="my-modal-parent">
      <Card style="width:100%">
        <Row style="line-height: 32px;">
          <Col span="2">
            <span style="color: red;">*</span>用户名
          </Col>
          <Col span="10">
            <Input prefix="ios-contact" border v-model.trim="register_name" placeholder="请输入注册用户名称" />
          </Col>

          <Col span="2" offset="1">
            <span style="color: red;">*</span>密码
          </Col>
          <Col span="8">
            <Input prefix="ios-lock" border v-model.trim="register_password" placeholder="请输入注册密码" />
          </Col>
          <br />
          <Col span="2">
            <span style="color: red;">*</span>电话
          </Col>
          <Col span="10">
            <Input prefix="md-phone-portrait" border v-model.trim="register_phone" placeholder="请输入电话号码" />
          </Col>
        </Row>
      </Card>
    </Modal>
	</div>
</template>

<script>
  import {
    Login,
    Register,
  } from "./httpcli/httpcli.js";

	export default {
		name: "login",
		data() {
			return {
				form: {
					username: '',
					password: ''
				},
        register_name: '',
        register_password: '',
        register_phone: '',
        registerModel: false,
			}
		},
		methods: {
			async handleSubmit(name) {
				console.log('username:', this.form.username)
				console.log('password:', this.form.password)

				let response = await Login(this.form.username, this.form.password);
				console.log('----- 登录响应:', response)
				if (response.code == 200) {
					this.$Message.info({
						content: '登录成功',
						duration: 2,
					});
					await this.$router.push('/menu?accountid=' + response.account_id);
				} else {
					this.$Message.error({
						content: '登录失败 errMsg:' + response.message,
						duration: 3,
					});
				}
			},
      handleRegister() {
        this.registerModel = true;
      },
      // 确定编辑
      async register_ok() {
        console.log('++++ 确定注册');

        let res = await Register(this.register_name, this.register_password, this.register_phone);
        console.log('++++ 注册结果 res:', res);

        if (res.code != 200) {
          this.$Message.error({
            content: res.message,
            duration: 3,
          })
        } else {
          this.$Message.info({
            content: res.message,
            duration: 3,
          })
        }

        this.register_name = '';
        this.register_password = '';
        this.register_phone = '';
      },
      // 取消编辑
      register_cancel() {
        console.log('++++ 取消注册');
        this.register_name = '';
        this.register_password = '';
        this.register_phone = '';
      },
		}
	}
</script>

<style lang="less" scoped>
	.login {
		background-color: #2c3e50;
		//background-image: url('../assets/3.png');
		height: 100vh;
	}

	.login_form {
		background-size: cover;
		background-position: center;
		position: absolute;
		right: 39%;
		top: 50%;
		transform: translateY(-60%);
		width: 320px;
	}
</style>
